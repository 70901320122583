import { StyleContainer } from "../../components/Common/StyleContainer";
import { Box, Typography } from "@mui/material";
import VisionBg1 from "../../assets/vision/vision_1.svg";
import VisionBg2 from "../../assets/vision/vision_2.svg";
import VisionBg3 from "../../assets/vision/vision_3.svg";
import VisionBg4 from "../../assets/vision/vision_4.svg";
import { useAppBreakpoints } from "../../hooks/useAppBreakpoints";

function Vision() {
  const data = [
    {
      isUnOrder: false,
      bgColor: "rgba(183, 228, 252,0.3)",
      pic: VisionBg1,
      title: "A Decentralized Money Beyond Borders",
      context:
        "In an increasingly interconnected world, our vision is to create decentralized money that transcends geographical boundaries, empowering individuals through the principles of decentralization.",
    },
    {
      isUnOrder: true,
      bgColor: "rgba(162, 239, 185,0.3)",
      pic: VisionBg2,
      title: "Perpetual Stability",
      context:
        "PUSD is a decentralized, delta-neutral stablecoin integrated with long-only perpetuals. This innovative approach allows PUSD to resolve the conventional stablecoin trilemma by providing a solution that is stable, decentralized, and capital efficient.",
    },
    {
      isUnOrder: false,
      bgColor: "rgba(242, 237, 187,0.3)",
      pic: VisionBg4,
      title: "Empowering Individuals",
      context:
        "“Only you control your money” is not just a slogan—it’s a promise to our users. We are committed to building a platform where individuals have full control over their financial assets, free from the control of centralized organizations.",
    },
    {
      isUnOrder: true,
      bgColor: "rgba(185, 191, 232,0.3)",
      pic: VisionBg3,
      title: "Technological Foundation",
      context:
        "Our vision is grounded in the robust and secure foundation of blockchain technology. By leveraging Ethereum’s decentralized infrastructure, we ensure that our platform remains secure, transparent, and scalable.",
    },
  ];

  const { isMatchMobile } = useAppBreakpoints();

  return (
    <StyleContainer className="mt-[80px] sm:mt-[40px] sm:px-0.75">
      <Typography
        className="mb-[30px] leading-[1.5] sm:mb-[20px]"
        fontSize={isMatchMobile ? 20 : 32}
        fontWeight={600}
      >
        Vision
      </Typography>
      <Box className="space-y-[60px] sm:space-y-[16px]">
        {data.map((item, index) => (
          <Box className="grid grid-cols-2 gap-0 sm:block" key={index}>
            <Box
              className={`flex items-center justify-center  ${
                isMatchMobile
                  ? ""
                  : item.isUnOrder
                  ? "order-2 rounded-r-[20px]"
                  : "order-1 rounded-l-[20px]"
              } sm:rounded-t-[14px] sm:h-[340px]`}
              sx={{
                backgroundColor: item.bgColor,
              }}
            >
              <img
                src={item.pic}
                alt="VisionIcon"
                className="h-[200px] sm:h-[122px]"
              />
            </Box>
            <Box
              className={`p-[40px] bg-[#FCFCFB] sm:px-[12px] sm:py-[20px]   ${
                isMatchMobile
                  ? ""
                  : item.isUnOrder
                  ? "order-1 rounded-l-[20px]"
                  : "order-2 rounded-r-[20px]"
              } sm:rounded-b-[14px]`}
            >
              <Typography
                className="leading-[1.5]"
                fontSize={isMatchMobile ? 14 : 20}
                fontWeight={600}
              >
                {item.title}
              </Typography>
              <Typography
                className="leading-[1.5] mt-[20px] sm:mt-[8px]"
                fontSize={isMatchMobile ? 14 : 18}
                fontWeight={400}
                color="text.secondary"
              >
                {item.context}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </StyleContainer>
  );
}

export default Vision;
