import {
  Action,
  AnyAction,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { persistReducer } from "reduxjs-toolkit-persist";
import autoMergeLevel2 from "reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "reduxjs-toolkit-persist/lib/storage";

import applicationReducer from "./application/slice";
import { updateVersion } from "./global/actions";
import globalReducer from "./global/slice";
import txReducer from "./tx/slice";
import lptReducer from "./lpt";
import poolsReducer from "./pools/slice";

const rootReducers = combineReducers({
  application: applicationReducer,
  global: globalReducer,
  tx: txReducer,
  lpt: lptReducer,
  pools: poolsReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["global", "pools", "lpt"],
};

const persistedReducer = persistReducer<any, AnyAction>(
  persistConfig,
  rootReducers
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof rootReducers>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  any,
  Action<string>
>;
export interface ActionReducer<T, V extends Action = Action> {
  (state: T | undefined, action: V): T;
}

store.dispatch(updateVersion());
