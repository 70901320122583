import { useMemo, useRef } from "react";
import { Chip, Card, Box, Typography, styled, BoxProps } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";

import { useAppBreakpoints } from "hooks/useAppBreakpoints";

import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import { useLaunchContext } from "context/LaunchContext";

interface IStepProps {
  title: string;
  info: string;
  chipText: string;
  chipColor: string;
  chipBgColor: string;
}

const SwiperStyle = {
  "--swiper-navigation-size": "20px",
  "--swiper-navigation-color": "#19232D",
} as React.CSSProperties;

export default function Step() {
  const ref = useRef<SwiperRef | null>(null);
  const { isMatchMobile } = useAppBreakpoints();
  const { activeItem, setActiveItem } = useLaunchContext();
  const StepArray: IStepProps[] = useMemo(() => {
    return [
      {
        title: "Phase 1: Heating Up",
        info: "In this phase, we will launch the Public Open Beta. A limited supply of PLP, LongOnly’s open interest, and PUSD will be made available for public testing, encouraging users to explore and experience the platform’s core features before scaling up.",
        chipText: "In progress",
        chipColor: "#03A4FF",
        chipBgColor: "#03A4FF30",
      },
      {
        title: "Phase 2: Acceleration",
        info: "This phase focuses on expanding PLP and LongOnly by significantly increasing their capacity while maintaining a limited supply of PUSD.",
        chipText: "Completed",
        chipColor: "#00D740",
        chipBgColor: "#00D74030",
      },
      {
        title: " Phase 3: The Holy Grail",
        info: "This phase centers on PUSD issuance, releasing a supply that aligns with the Genesis Airdrop target, along with further expansions in LongOnly capacity. The key highlight of this phase is the promotion of the delta-neutral stablecoin.",
        chipText: "Completed",
        chipColor: "#F8B409",
        chipBgColor: "#F8B40930",
      },
    ];
  }, []);

  return (
    <div className="space-y-[40px] sm:space-y-[20px]">
      <Typography
        variant="h2"
        fontWeight={600}
        className="text-[40px] sm:text-[24px]"
      >
        Launch Plan
      </Typography>
      {!isMatchMobile && (
        <div className="grid grid-cols-3 gap-[20px]">
          {StepArray.map((item: IStepProps, index: number) => {
            return <StepItemRennderer item={item} index={index} key={index} />;
          })}
        </div>
      )}
      {isMatchMobile && (
        <SwiperOutBox className="sm:-mx-1.5 sm:px-[12px]">
          <Swiper
            loop
            ref={ref}
            navigation
            style={SwiperStyle}
            modules={[Navigation]}
          >
            {StepArray.map((item: IStepProps, index: number) => (
              <Box className="w-full">
                <SwiperSlide>
                  <StepItemRennderer item={item} key={index} index={index} />
                </SwiperSlide>
              </Box>
            ))}
          </Swiper>
        </SwiperOutBox>
      )}
    </div>
  );
}
const SwiperOutBox = styled(Box)<BoxProps>(({ theme }) => ({
  ".swiper": {
    ".swiper-button-prev, .swiper-button-next": {
      fontWeight: 700,
    },
  },
}));

const StepItemRennderer = ({
  item,
  index,
}: {
  item: IStepProps;
  index: number;
}) => {
  const { activeItem, setActiveItem } = useLaunchContext();
  const onMouseEnter = (e) => {
    setActiveItem(index);
  };
  const onMouseLeave = (e) => {
    setActiveItem(0);
  };

  return (
    <Card
      className="h-[342px] py-[24px] px-[26px] space-y-1 relative sm:px-[16px] sm:mx-[32px] sm:rounded-[12px]"
      sx={{
        border: `1px solid ${index === activeItem ? "#19232D" : "transparent"}`,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Typography variant="body1" fontWeight={600} className="text-[20px]">
        {item.title}
      </Typography>
      <Typography color="text.secondary" className="text-[16px]">
        {item.info}
      </Typography>
      <Box className="absolute bottom-1.5 left-0 w-full px-[26px] sm:px-[16px]">
        {index === 0 ? (
          <Chip
            label={
              <Typography
                sx={{
                  color: `${item.chipColor}`,
                }}
                className="flex items-center space-x-0.25"
              >
                {/* <CheckCircle color="inherit" sx={{ fontSize: 20 }} /> */}
                <Typography color="text.primary">{item.chipText}</Typography>
              </Typography>
            }
            sx={{
              background: `${item.chipBgColor}`,
            }}
            className="w-full h-[36px] px-[12px] rounded-[10px]"
          />
        ) : null}
      </Box>
    </Card>
  );
};
