import { Reducer, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../index";

interface StateProps {
  pools: Array<any>;
}

const initialState: StateProps = {
  pools: [],
};

export const slice = createSlice({
  name: "pools",
  initialState,
  reducers: {
    setPools(state, { payload }) {
      state.pools = payload;
    },
  },
});

export const { setPools } = slice.actions;

export const poolsState = (state: RootState) => state.pools;

export const selectPools = createSelector(poolsState, (state) => state.pools);

export default slice.reducer as Reducer<typeof initialState>;
