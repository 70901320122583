import { StyleContainer } from "components/Common/StyleContainer";
import { Box, Typography } from "@mui/material";
import IconPurecash from "../../assets/purecash.svg";
import { useAppBreakpoints } from "hooks/useAppBreakpoints";

function Brand() {
  const { isMatchMobile } = useAppBreakpoints();

  return (
    <StyleContainer className="mt-[80px] sm:mt-[40px]">
      <Typography
        className="mb-[30px] leading-[1.5] sm:mb-[20px]"
        fontSize={isMatchMobile ? 20 : 32}
        fontWeight={600}
      >
        Brand
      </Typography>

      <Box className="flex sm:flex-col sm:space-y-[20px]">
        <Box className="flex-1">
          <Typography
            className="text-[20px] sm:text-[14px]"
            color="text.secondary"
          >
            The Pure.cash logo, designed as a windmill, reflects the platform’s
            core values. Windmills traditionally symbolize energy,
            self-sustainability, and continuous motion—qualities aligned with
            Pure.cash’s decentralized mechanisms, which operate without central
            control.
          </Typography>
        </Box>
        <Box className="w-[400px] flex items-center justify-center sm:w-auto">
          <img
            src={IconPurecash}
            alt="pure.cash"
            className="w-[140px] h-[140px]"
          />
        </Box>
      </Box>
      <Box className="mt-2">
        <Typography
          className="text-[20px] sm:text-[14px]"
          color="text.secondary"
        >
          The Ethereum-inspired blades emphasize the protocol’s deep integration
          with the Ethereum network, reinforcing its foundation of security,
          stability, and decentralization.
        </Typography>
      </Box>
    </StyleContainer>
  );
}

export default Brand;
