import { useEffect, useState, useMemo } from "react";

import { usePoolsRequest, useTokensPrice, useMultiTokens } from "./useRequest";

import { isEmpty } from "lodash";
import { setPools } from "../state/pools/slice";
import { useAppDispatch } from "../state/hooks";

export function usePoolsFetch() {
  const [loading, setLoading] = useState<boolean>(true);
  const [poolList, setPoolList] = useState<Array<any>>([]);
  const { data, isLoading, refetch } = usePoolsRequest();
  const dispatch = useAppDispatch();

  const tokenAddresses = useMemo(() => {
    if (!data || !data?.markets) {
      return null;
    }
    return data?.markets.map((pool) => pool.address);
  }, [data]);

  const { tokensMultiPrice } = useMultiTokens(tokenAddresses);

  useEffect(() => {
    if (!isLoading) {
      setLoading(isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isEmpty(data) || !tokensMultiPrice) {
      return;
    }
    const refactorData = data?.markets.map((item) => {
      const tokenPriceItem = tokensMultiPrice?.marketPrices?.find(
        (tokenItem: any) => tokenItem.address === item.address
      );

      if (!tokenPriceItem) {
        return;
      }
      return {
        ...item,
        ...tokenPriceItem,
      };
    });
    setPoolList(refactorData);
    dispatch(setPools(refactorData));
  }, [data, tokensMultiPrice]);

  return {
    poolList,
    loading,
    refetchPools: refetch,
  };
}
