import { Hash } from "@wagmi/core";
import { mainnet as ethereum, sepolia } from "wagmi/chains";

type ContractMap = Record<string, Record<number, string>>;
type EnvContractMap = Record<string, Record<string, string>>;

const PROD_CONTRACTS: Record<string, string> = {
  PositionRouter: "0xc3B609357539A35673cc50e5Ca4fA57da1BFeC7b",
  OrderBookUpgradeable: "0x0A41c964781312413Ac51d1A11efff1D0cfF2832",
  RouterUpgradeable: "0x911a71DDa951958913219f7cBD7e4a297ca52B3B",
  EFC: "0xe6bf4e8A735d4F83a662d5aD430159Aa97eAE37E",
  FeeDistributor: "0x3C77EEB8eC4716a6389a522eD590FbbD261ABE8e",
  RewardCollector: "0x2920bD2FC3ca629F98DD8B24C89C6E01b3dDd710",
  RewardFarm: "0xb46a80b157b7eA025530062B761D01AA92f0e354",
  PoolFactory: "0xA91680161fBCeA942e164B42445aD6130D01541F",
  NonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
  UniPoolFactory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
  Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
};

const EnvMapEthereum: EnvContractMap = {
  development: {
    PositionRouter: "0xC31C597E4cF14d4672F6a19D10EB871C611D2712",
    OrderBookUpgradeable: "0xF98e4bd7Ebd5801D73022A103Ff2cc1D62c0707d",
    RouterUpgradeable: "0x305Aa369b1dC4163CA412C005485eAE063C6eD8B",
    EFC: "0x5D532F910aDa7A01811756Ced2dC20756985004D",
    FeeDistributor: "0x9482Bde4B5C39Bf6e2cF67Ac404e0e6C7Dc9e33d",
    RewardCollector: "0xa9b315e6951a1cEC5e63B73f5A4708da9239DC35",
    RewardFarm: "0x3dFA168eF4B90157c1A6E5fb2c62B4468FCFA8e9",
    PoolFactory: "0xC87A6Fd6500D1711b5915d96605b3C39a288107f",
    NonfungiblePositionManager: "0x622e4726a167799826d1E1D150b076A7725f5D81",
    UniPoolFactory: "0x4893376342d5d7b3e31d4184c08b265e5ab2a3f6",
    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },
  production: {
    PositionRouter: "0xC31C597E4cF14d4672F6a19D10EB871C611D2712",
    OrderBookUpgradeable: "0x7A41fF48ab803D1991Deb2Fa56229534c86436a1",
    RouterUpgradeable: "0xcb4010f59be885E4A8c85143d78e612EC143FBd6",
    EFC: "0x03a1526e104123d91E1954f5539D4aE9952C02A8",
    FeeDistributor: "0x64aAE8719f16F4D7179356eb87A49c53f7452984",
    RewardCollector: "0x2920bD2FC3ca629F98DD8B24C89C6E01b3dDd710",
    RewardFarm: "0xee32D1B3713214e68B0d997400ad55328120B228",
    NonfungiblePositionManager: "0x622e4726a167799826d1E1D150b076A7725f5D81",
    PoolFactory: "0x9c08BD11C9a377D73Ae94dC3B56D6d4613981a8F",
    UniPoolFactory: "0x4893376342d5d7b3e31d4184c08b265e5ab2a3f6",
    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },
};

const EnvMapEthereumV2: EnvContractMap = {
  development: {
    PositionRouter: "0xEA9517991Eea0A03A0EDEDFaf04d404A29a750BE",
    PositionRouter2: "0x1AdF53418e5F18C008D50cd4d08AB3Bc1b4DFe04",
    Reader: "0x0EF10bff885C9c216A1C199BFef4278b2Ff03B51",
    MarketManager: "0x2Bea52BC3F018f7419488174B2E0d64f5e391f65",
    DirectExecutablePlugin: "0xb584BD062B99cC0103d31B1cB53108036Bb4d673",
    Staking: "0xB402d1A22519Bb88E41D280c884ad10255896108",
  },
  production: {
    PositionRouter: "0xc1161ef24Bb4B80960992d287680295aEB765E31",
    PositionRouter2: "0xF495DF0AAAF689aaA0749BE7a6Dd2225c8744B57",
    Reader: "0x1793BC223b8cf9590123955f88588230DBfA91Aa",
    MarketManager: "0xE862Ec44481C323AEc12cc0fad626a7Ad52F74E8",
    DirectExecutablePlugin: "0xC7da5db761fF6545653e97444488661475D36969",
    Staking: "0x7DfA5FA4e8a2E284e3Dd75889ab5aa9AF92673F8",
  },
};

const PROD_CONTRACTS_V2: Record<string, string> = {
  PositionRouter: "0xc1161ef24Bb4B80960992d287680295aEB765E31",
  PositionRouter2: "0xF495DF0AAAF689aaA0749BE7a6Dd2225c8744B57",
  Reader: "0x1793BC223b8cf9590123955f88588230DBfA91Aa",
  MarketManager: "0xE862Ec44481C323AEc12cc0fad626a7Ad52F74E8",
  DirectExecutablePlugin: "0xC7da5db761fF6545653e97444488661475D36969",
  Staking: "0x7DfA5FA4e8a2E284e3Dd75889ab5aa9AF92673F8",
};

const EnvMap: EnvContractMap = {
  development: PROD_CONTRACTS,
  production: PROD_CONTRACTS,
};

export const contractEthereum = EnvMapEthereum[process.env.NODE_ENV];
export const contractEthereumV2 = EnvMapEthereumV2[process.env.NODE_ENV];
export const contract = EnvMap[process.env.NODE_ENV];
export const contractV2 = PROD_CONTRACTS_V2;

export const CONTRACTS: ContractMap = {
  PositionRouter: {
    [sepolia.id]: contractEthereum.PositionRouter,
    [ethereum.id]: contract.PositionRouter,
  },
  PositionRouter2: {
    [sepolia.id]: contractEthereum.PositionRouter2,
    [ethereum.id]: contract.PositionRouter2,
  },
  OrderBookUpgradeable: {
    [sepolia.id]: contractEthereum.OrderBookUpgradeable,
    [ethereum.id]: contract.OrderBookUpgradeable,
  },
  RouterUpgradeable: {
    [sepolia.id]: contractEthereum.RouterUpgradeable,
    [ethereum.id]: contract.RouterUpgradeable,
  },
  EFC: {
    [sepolia.id]: contractEthereum.EFC,
    [ethereum.id]: contract.EFC,
  },
  FeeDistributor: {
    [sepolia.id]: contractEthereum.FeeDistributor,
    [ethereum.id]: contract.FeeDistributor,
  },
  RewardCollector: {
    [sepolia.id]: contractEthereum.RewardCollector,
    [ethereum.id]: contract.RewardCollector,
  },
  NonfungiblePositionManager: {
    [sepolia.id]: contractEthereum.NonfungiblePositionManager,
    [ethereum.id]: contract.NonfungiblePositionManager,
  },
  PoolFactory: {
    [sepolia.id]: contractEthereum.PoolFactory,
    [ethereum.id]: contract.NonfungiblePositionManager,
  },
  UniPoolFactory: {
    [sepolia.id]: contractEthereum.UniPoolFactory,
    [ethereum.id]: contract.UniPoolFactory,
  },
  Multicall: {
    [sepolia.id]: contractEthereum.Multicall,
    [ethereum.id]: contract.Multicall,
  },
};

export const CONTRACTSV2: ContractMap = {
  PositionRouter: {
    [sepolia.id]: contractEthereumV2.PositionRouter,
    [ethereum.id]: contractV2.PositionRouter,
  },
  PositionRouter2: {
    [sepolia.id]: contractEthereumV2.PositionRouter2,
    [ethereum.id]: contractV2.PositionRouter2,
  },
  OrderBookUpgradeable: {
    [sepolia.id]: contractEthereumV2.OrderBookUpgradeable,
    [ethereum.id]: contractV2.OrderBookUpgradeable,
  },
  RouterUpgradeable: {
    [sepolia.id]: contractEthereumV2.RouterUpgradeable,
    [ethereum.id]: contractV2.RouterUpgradeable,
  },
  Reader: {
    [sepolia.id]: contractEthereumV2.Reader,
    [ethereum.id]: contractV2.Reader,
  },
  MarketManager: {
    [sepolia.id]: contractEthereumV2.MarketManager,
    [ethereum.id]: contractV2.MarketManager,
  },
  DirectExecutablePlugin: {
    [sepolia.id]: contractEthereumV2.DirectExecutablePlugin,
    [ethereum.id]: contractV2.DirectExecutablePlugin,
  },
  Staking: {
    [sepolia.id]: contractEthereumV2.Staking,
    [ethereum.id]: contractV2.Staking,
  },
};

export const getContractAddress = (chainId: number, contractName: string) => {
  if (!CONTRACTS[contractName][chainId]) {
    console.warn(`Unknown contract "${contractName}" for chainId ${chainId}`);
    return "" as Hash;
  }

  return CONTRACTS[contractName][chainId] as Hash;
};

export const getContractAddressV2 = (chainId: number, contractName: string) => {
  if (!CONTRACTSV2[contractName][chainId]) {
    console.warn(`Unknown contract "${contractName}" for chainId ${chainId}`);
    return "" as Hash;
  }

  return CONTRACTSV2[contractName][chainId] as Hash;
};

export const selectContractByVersion = (
  version: string = "V2",
  chainId: number,
  contractName: string
) => {
  if (version === "V2") {
    return getContractAddressV2(chainId, contractName);
  } else {
    return getContractAddress(chainId, contractName);
  }
};
