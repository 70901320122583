import { alpha, Box, Button, Typography } from "@mui/material";
import { StyleContainer } from "components/Common/StyleContainer";
import AirdropImg from "../../assets/airdrop/airdrop.svg";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import rightIcon from "../../assets/right.svg";

export default function Airdrop() {
  return (
    <StyleContainer className="mt-[60px] sm:mt-[40px]">
      <Typography
        className="mb-[30px] text-[32px] leading-[1.5] sm:mb-[20px] sm:text-[20px]"
        fontWeight={600}
      >
        Airdrop
      </Typography>
      <Box className="flex justify-between mt-[32px] sm:flex-col sm:mt-[40px]">
        <Tooltip
          title="View More"
          sx={{
            color: "#FFF",
          }}
        >
          <Box
            className="flex sm:justify-center"
            component={Link}
            to="/launch"
            title="View more"
          >
            <img src={AirdropImg} alt="AirdropImg" className="sm:h-[88px]" />
          </Box>
        </Tooltip>
        <Box className="text-right sm:text-center sm:mt-[40px]">
          <Typography
            variant="h2"
            className="text-[18px] leading-[1.5] text-center sm:text-[16px] "
            color="#19232D"
          >
            $PURE <strong>Genesis Airdrop</strong> is Now Live
          </Typography>
          <Button
            variant="outlined"
            className="mt-[32px] rounded-full text-[16px] px-[24px] h-[40px] sm:mt-[20px]"
            href="https://app.pure.cash/airdrop"
            target="_blank"
            sx={{
              border: "1px solid #19232D",
              color: "#19232D",
              "&:hover": {
                border: `1px solid ${alpha("#19232D", 0.4)}`,
                background: "#F7F7F6",
              },
            }}
          >
            <span>Join early access</span>
            <img src={rightIcon} alt="rightIcon" className="ml-[8px]" />
          </Button>
        </Box>
      </Box>
    </StyleContainer>
  );
}
