import Banner from "./banner";
import Airdrop from "./Airdrop";
import Cards from "./Cards";

function Index() {
  return (
    <div className="relative -mt-2 sm:mt-0">
      <Banner />
      <Airdrop />
      <Cards />
    </div>
  );
}

export default Index;
