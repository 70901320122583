import { mainnet, sepolia } from "wagmi/chains";

import { Env } from "./constants";

interface ApiConfig {
  V2: string;
}

const BACKEND_HOST_DEV: Record<number, ApiConfig> = {
  [sepolia.id]: {
    V2: "https://api-xusd.xusd.me/v1",
  },
  [mainnet.id]: {
    V2: "https://api.pure.cash/v1",
  },
};

const BACKEND_HOST_PROD: Record<number, ApiConfig> = {
  [sepolia.id]: {
    V2: "https://api-xusd.xusd.me/v1",
  },
  [mainnet.id]: {
    V2: "https://api.pure.cash/v1",
  },
};

const BACKEND_HOST = {
  [Env.development]: BACKEND_HOST_DEV,
  [Env.production]: BACKEND_HOST_PROD,
};

export const BACKEND_HOST_URL: Record<number, ApiConfig> =
  BACKEND_HOST[process.env.NODE_ENV as Env];

const BACKEND_AIRDROP_HOST = {
  [Env.development]: "https://api-xusd.xusd.me",
  [Env.production]: "https://longonly.org/",
};

export const BACKEND_AIRDROP_HOST_URL =
  BACKEND_AIRDROP_HOST[process.env.NODE_ENV as Env];

export function getServerBaseUrl(version: string, chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  return BACKEND_HOST_URL[chainId][version];
}

export function getServerUrl(version = "V1", chainId: number, path: string) {
  return `${getServerBaseUrl(version, chainId)}${path}`;
}
