import {
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";

export interface ILaunchProps {
  activeItem: number;
  setActiveItem: (index: number) => void;
}

export const LaunchContext = createContext<ILaunchProps>({
  activeItem: 0,
  setActiveItem: (index: number) => {},
});

export function LaunchContextProvider({ children }: { children: ReactNode }) {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const setActiveItem = (index: number) => {
    setActiveItemIndex(index);
  };

  const value = useMemo(() => {
    return {
      activeItem: activeItemIndex,
      setActiveItem,
    };
  }, [activeItemIndex]);

  return (
    <LaunchContext.Provider value={value}>{children}</LaunchContext.Provider>
  );
}

export const useLaunchContext = () => {
  return useContext(LaunchContext);
};
