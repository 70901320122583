import { Box, Typography, Card, alpha } from "@mui/material";
// import { useMediaQuery } from "@mui/material";
// import { useAppBreakpoints } from "hooks/useAppBreakpoints";

import Info1 from "../../assets/pusd/info1.svg";
import Info2 from "../../assets/pusd/info2.svg";
import User1 from "../../assets/pusd/user1.svg";
import User2 from "../../assets/pusd/user2.svg";
import Book1 from "../../assets/pusd/book1.svg";
import Book2 from "../../assets/pusd/book2.svg";
import InfoTitle from "../../assets/pusd/infoTitle.svg";
import { StyleContainer } from "components/Common/StyleContainer";

function Principle() {
  // const { isMatchMobile } = useAppBreakpoints();
  // const TryButton = () => {
  //   return (
  //     false && (
  //       <Button
  //         disabled
  //         component="a"
  //         variant="contained"
  //         size="large"
  //         sx={{
  //           height: isMatchMobile ? 38 : 40,
  //           width: isMatchMobile ? 110 : "auto",
  //           color: alpha("#000", 0.9),
  //           padding: isMatchMobile ? "6px 12px" : "6px 24px",
  //           background:
  //             "linear-gradient(64deg, #03AAF7 32.22%, #02F1A0 93.46%)",
  //           fontWeight: 600,
  //           borderRadius: isMatchMobile ? 35 : 35,
  //           fontSize: `16px !important`,
  //           "&.Mui-disabled": {
  //             color: alpha("#000", 0.6),
  //           },
  //         }}
  //       >
  //         Try now
  //       </Button>
  //     )
  //   );
  // };
  return (
    <StyleContainer className="mt-[80px] sm:mt-[40px]">
      <Box className="relative w-full space-y-[24px]">
        <Typography className="text-[32px] sm:text-[20px]" fontWeight={600}>
          The Core Mechanism behind PUSD
        </Typography>

        <Card className="bg-[#FCFCFB] h-[270px] sm:h-auto p-0 flex justify-between items-center sm:flex-col sm:rounded-[12px]">
          <Box
            className="h-full flex-1 p-[40px] sm:w-full sm:px-[14px] sm:py-[24px]"
            sx={{
              backgroundColor: alpha("#B7E4FC", 0.3),
            }}
          >
            <Box className="flex items-center sm:space-x-[16px]">
              <img
                src={User1}
                alt=""
                className="w-[80px] h-[80px] sm:w-[40px] sm:h-[40px]"
              />
              <img
                src={Info1}
                alt=""
                className="flex-1 w-[320px] h-[83px] sm:w-[200px] sm:h-auto"
              />
              <img
                src={Book1}
                alt=""
                className="w-[80px] h-[80px] sm:w-[40px] sm:h-[40px]"
              />
            </Box>
            <Box className="sm:px-[2px]">
              <Typography className="flex items-center text-[18px] mt-[26px] mb-[12px] sm:text-[16px]">
                <img
                  src={InfoTitle}
                  alt=""
                  className="w-[16px] h-[20px] sm:w-[14px] sm:h-[17px] mr-[6px]"
                />
                What happens in the smart contract?
              </Typography>
              <Typography
                className="text-[16px] sm:text-[12px]"
                color="text.secondary"
              >
                1. Open a 1 ETH short position with 1 ETH as margin. <br />
                2. Mint PUSD with a 1:1 value to the margin.
              </Typography>
            </Box>
          </Box>
          <Box className="w-[366px] p-[40px] sm:w-full sm:px-[10px] sm:py-[24px] sm:text-left">
            <Typography
              fontWeight={600}
              className="text-[20px] mb-[20px] sm:mb-[12px] sm:text-[20px]"
            >
              Mint PUSD
            </Typography>
            <Typography
              className="mt-0.5 text-[18px] sm:text-[18px]"
              color="text.secondary"
            >
              The process of users swapping ETH for PUSD is the process of PUSD
              being minted.
            </Typography>
            {/* {!isMatchMobile && TryButton()} */}
          </Box>
          {/* {isMatchMobile && TryButton()} */}
        </Card>

        <Card className="bg-[#FCFCFB] h-[270px] sm:h-auto p-0 flex justify-between items-center sm:flex-col sm:rounded-[12px]">
          <Box className="w-[366px] p-[40px] sm:w-full sm:px-[10px] sm:py-[24px] sm:text-left sm:order-1">
            <Typography
              fontWeight={600}
              className="text-[20px] mb-[20px] sm:mb-[12px] sm:text-[20px]"
            >
              Burn PUSD
            </Typography>
            <Typography
              className="mt-0.5 text-[18px] sm:text-[18px]"
              color="text.secondary"
            >
              The process of users swapping PUSD for ETH is the process of PUSD
              being burned.
            </Typography>
            {/* {!isMatchMobile && TryButton()} */}
          </Box>
          <Box
            className="h-full flex-1 p-[40px] sm:w-full sm:px-[10px] sm:py-[24px]"
            sx={{
              backgroundColor: alpha("#A2EFB9", 0.2),
            }}
          >
            <Box className="flex items-center sm:space-x-[16px]">
              <img
                src={User2}
                alt=""
                className="w-[80px] h-[80px] sm:w-[40px] sm:h-[40px]"
              />
              <img
                src={Info2}
                alt=""
                className="flex-1 w-[320px] h-[83px] sm:w-[200px] sm:h-auto"
              />
              <img
                src={Book2}
                alt=""
                className="w-[80px] h-[80px] sm:w-[40px] sm:h-[40px]"
              />
            </Box>
            <Box className="sm:px-[2px]">
              <Typography className="flex items-center text-[18px] mt-[26px] mb-[12px] sm:text-[16px]">
                <img
                  src={InfoTitle}
                  alt=""
                  className="w-[16px] h-[20px] sm:w-[14px] sm:h-[17px] mr-[6px]"
                />
                What happens in the smart contract?
              </Typography>
              <Typography
                className="text-[16px] sm:text-[12px]"
                color="text.secondary"
              >
                1. Burn 4000 PUSD. <br />
                2. Close an ETH short position corresponding to 4000 PUSD.
              </Typography>
            </Box>
          </Box>
          {/* {isMatchMobile && TryButton()} */}
        </Card>
      </Box>
    </StyleContainer>
  );
}

export default Principle;
