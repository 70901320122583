import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";

export const baseState = (state: RootState) => state.lpt;

export const selectTokens = createSelector(baseState, (state) => {
  const { tokens } = state;
  return tokens;
});

export const selectTokenByAddress = (address: string) =>
  createSelector(selectTokens, (tokens) => {
    if (!address || !tokens) {
      return {};
    }
    return tokens[address] || {};
  });
