import { useState, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { alpha, styled, useTheme } from "@mui/material/styles";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  Cell,
} from "recharts";
import { toDecimalPlaces, multipliedBy, div, formatNumber } from "../../utils";
import { isEmpty } from "lodash";
import { SectionLoading } from "../../components/loading";
import { useAppBreakpoints } from "../../hooks/useAppBreakpoints";
import { useLaunchContext } from "context/LaunchContext";

const renderQuarterTick = (tickProps) => {
  const { x, y, payload, index, width } = tickProps;
  return index === 0 ? (
    <>
      <svg
        x={payload.offset + 5}
        y={y + 11}
        width="8"
        height="8"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="2.80469" cy="2.81812" r="2.5" fill="#03D3B8" />
      </svg>
      <text fill="#000" className="text-xs" x={payload.offset + 18} y={y + 20}>
        {payload.value}
      </text>
    </>
  ) : (
    <text fill="#86909C" className="text-xs" x={x - 36} y={y + 20}>
      {payload.value}
    </text>
  );
};

export const StyledTooltip = styled("div")(({ theme }) => ({
  background: theme.palette.common.white,
  borderColor: theme.palette.divider,
  borderWidth: 1,
  padding: theme.spacing(2),
  outline: 0,
  borderRadius: "20px",
  boxShadow: "0px 8px 18px 0px #898A8B66",
}));

export default function Index({ data: currentValue, pool }) {
  const [isEnter, setIsEnter] = useState(false);
  const { isMatchMobile } = useAppBreakpoints();
  const { activeItem, setActiveItem } = useLaunchContext();
  const onHandleMouseEnterBarChart = (e) => {
    setIsEnter(true);
    if (!Number.isNaN(e.activeTooltipIndex)) {
      setActiveItem(e.activeTooltipIndex);
    }
  };
  const onHandleMouseLeaveBarChart = () => {
    setIsEnter(false);
    setActiveItem(0);
  };

  const yTickFormatter = (tick, index) => {
    return `${index * 20}%`;
  };

  const targetValues = useMemo(() => {
    if (!pool) {
      return {};
    }
    // 先缩小100倍
    return {
      "Heating Up": {
        targetPLP: Number(
          toDecimalPlaces(multipliedBy(5000, pool.indexPrice), 0)
        ),
        targetLongOnly: Number(
          toDecimalPlaces(
            multipliedBy(multipliedBy(5000, pool.indexPrice), 1.1),
            0
          )
        ),
        targetPUSD: Number(
          toDecimalPlaces(
            multipliedBy(multipliedBy(5000, pool.indexPrice), 0.2),
            0
          )
        ),
        targetPLPValue: Number(
          multipliedBy(div(multipliedBy(5000, pool.indexPrice), 300000000), 100)
        ),
        targetLongOnlyValue: Number(
          multipliedBy(
            div(
              multipliedBy(multipliedBy(5000, pool.indexPrice), 1.1),
              1270000000
            ),
            100
          )
        ),
        targetPUSDValue: Number(
          multipliedBy(
            div(
              multipliedBy(multipliedBy(5000, pool.indexPrice), 0.2),
              1000000000
            ),
            100
          )
        ),
      },
      Acceleration: {
        targetPLP: 300000000,
        targetLongOnly: 330000000,
        targetPUSD: 60000000,
        targetPLPValue: Number(multipliedBy(div(300000000, 300000000), 100)),
        targetLongOnlyValue: Number(
          multipliedBy(div(330000000, 1270000000), 100)
        ),
        targetPUSDValue: Number(multipliedBy(div(60000000, 1000000000), 100)),
      },
      "The Holy Grail": {
        targetPLP: 300000000,
        targetLongOnly: 1270000000,
        targetPUSD: 1000000000,
        targetPLPValue: 100,
        targetLongOnlyValue: 100,
        targetPUSDValue: 100,
      },
    };
  }, [pool]);

  const refactorData = useMemo(() => {
    if (isEmpty(targetValues) || isEmpty(currentValue)) {
      return [];
    }
    return ["Heating Up", "Acceleration", "The Holy Grail"].map((item) => {
      return {
        type: item,
        PLP: currentValue.PLP.usdValue,
        PLPDisplay: Number(
          multipliedBy(
            div(currentValue.PLP.usdValue, targetValues[item]?.targetPLP),
            100
          )
        ),
        LongOnly: currentValue.LongOpenInterest.usdValue,
        LongOnlyDisplay: Number(
          multipliedBy(
            div(
              currentValue.LongOpenInterest.usdValue,
              targetValues[item]?.targetLongOnly
            ),
            100
          )
        ),
        PUSD: currentValue.PUSD.value,
        PUSDDisplay: Number(
          multipliedBy(
            div(currentValue.PUSD.value, targetValues[item]?.targetPUSD),
            100
          )
        ),
        ...targetValues[item],
      };
    });
  }, [currentValue, targetValues]);
  return (
    <Box className="relative space-y-5 sm:space-y-3">
      <Box className="w-full h-[360px]">
        {isEmpty(refactorData) ? (
          <Box className="flex items-center justify-center w-full h-full">
            <SectionLoading />
          </Box>
        ) : (
          <Box className="h-full">
            <Typography color="text.secondary" className="mb-0.5">
              Target%
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={refactorData}
                margin={{
                  top: 10,
                  right: 0,
                  left: -5,
                  bottom: 5,
                }}
                onMouseMove={onHandleMouseEnterBarChart}
                onMouseLeave={onHandleMouseLeaveBarChart}
                barSize={isMatchMobile ? 20 : 40}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="type" tick={renderQuarterTick} />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={yTickFormatter}
                  tickCount={6}
                  type="number"
                  domain={[0, 100]}
                  allowDataOverflow
                />
                <Tooltip
                  cursor={false}
                  wrapperStyle={{
                    borderImage: "none !important",
                    background: "transparent",
                    boxShadow: "unset",
                    zIndex: 9999,
                  }}
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      return (
                        <StyledTooltip className="space-y-1">
                          <div className="space-y-0.125">
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              component="div"
                              fontSize={14}
                              className="space-x-0.5"
                            >
                              <Typography
                                fontWeight={700}
                                variant="inherit"
                                component="span"
                              >
                                PLP
                              </Typography>
                              <span>{`Current / Phase Target: `}</span>
                            </Typography>
                            <Typography
                              variant="caption"
                              component="div"
                              fontSize={14}
                            >
                              ${`${Number(payload[0].payload.PLP)}`}
                              <Typography
                                component="span"
                                color="text.secondary"
                                variant="inherit"
                              >
                                / $
                                {formatNumber(payload[0].payload.targetPLP, 0)}
                              </Typography>
                            </Typography>
                          </div>
                          <div className="space-y-0.125">
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              component="div"
                              fontSize={14}
                              className="space-x-0.5"
                            >
                              <Typography
                                fontWeight={700}
                                variant="inherit"
                                component="span"
                              >
                                Open Interest
                              </Typography>
                              <span>{`Current / Phase Target: `}</span>
                            </Typography>
                            <Typography
                              variant="caption"
                              component="div"
                              fontSize={14}
                            >
                              ${`${Number(payload[0].payload.LongOnly)}`}
                              <Typography
                                component="span"
                                color="text.secondary"
                                variant="inherit"
                              >
                                / $
                                {formatNumber(
                                  payload[0].payload.targetLongOnly,
                                  0
                                )}
                              </Typography>
                            </Typography>
                          </div>
                          <div className="space-y-0.125">
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              component="div"
                              fontSize={14}
                              className="space-x-0.5"
                            >
                              <Typography
                                fontWeight={700}
                                variant="inherit"
                                component="span"
                              >
                                PUSD
                              </Typography>
                              <span>{`Current / Phase Target: `}</span>
                            </Typography>
                            <Typography
                              variant="caption"
                              component="div"
                              fontSize={14}
                            >
                              {`${Number(payload[0].payload.PUSD)}`} PUSD
                              <Typography
                                component="span"
                                color="text.secondary"
                                variant="inherit"
                              >
                                /{" "}
                                {formatNumber(payload[0].payload.targetPUSD, 0)}{" "}
                                PUSD
                              </Typography>
                            </Typography>
                          </div>
                        </StyledTooltip>
                      );
                    }
                    return null;
                  }}
                />
                <Bar
                  dataKey="PLPDisplay"
                  fill={isEnter ? "#03A4FF60" : "#03A4FF"}
                  activeBar={<Rectangle fill="#03A4FF" />}
                  stackId="plp"
                />
                <Bar
                  dataKey="targetPLPValue"
                  //fill={"#D0ECFC"}
                  stackId="plp"
                >
                  {refactorData.map((entry, index) => {
                    return (
                      <Cell
                        fill={index === activeItem ? "#03A4FF" : "#D0ECFC"}
                        key={`cell-${index}`}
                      />
                    );
                  })}
                </Bar>

                <Bar
                  dataKey="LongOnlyDisplay"
                  fill={isEnter ? "#00D74060" : "#00D740"}
                  activeBar={<Rectangle fill="#00D740" />}
                  stackId="longOnly"
                />
                <Bar
                  dataKey="targetLongOnlyValue"
                  fill={"#CDF7D9"}
                  stackId="longOnly"
                >
                  {refactorData.map((entry, index) => {
                    return (
                      <Cell
                        fill={index === activeItem ? "#00D740" : "#CDF7D9"}
                        key={`cell-${index}`}
                      />
                    );
                  })}
                </Bar>
                <Bar
                  dataKey="PUSDDisplay"
                  fill={isEnter ? "#F8B40960" : "#F8B409"}
                  activeBar={<Rectangle fill="#F8B409" />}
                  stackId="PUSD"
                />
                <Bar dataKey="targetPUSDValue" fill={"#FCEFD0"} stackId="PUSD">
                  {refactorData.map((entry, index) => {
                    return (
                      <Cell
                        fill={index === activeItem ? "#F8B409" : "#FCEFD0"}
                        key={`cell-${index}`}
                      />
                    );
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </Box>
      {/* {!isEmpty(refactorData) ? (
        <Box className="flex items-center justify-between sm:flex-col sm:space-y-1 sm:items-start sm:pl-1.5">
          <div className="flex space-x-0.5 items-center sm:text-xs">
            <Box
              className="w-[16px] h-[16px]"
              sx={{
                background: "#03A4FF",
                borderRadius: "2px",
              }}
            ></Box>{" "}
            <span className="text-base">PLP</span>{" "}
            <span className="text-sm">Target TVL: $300M–$500M</span>
          </div>
          <div className="flex space-x-0.5 items-center sm:text-xs">
            <Box
              className="w-[16px] h-[16px]"
              sx={{
                background: "#00D740",
                borderRadius: "2px",
              }}
            ></Box>{" "}
            <span className="text-base">LongOnly</span>{" "}
            <span className="text-sm">Target Open Interest: $1.27B–$1.45B</span>
          </div>
          <div className="flex space-x-0.5 items-center sm:text-xs">
            <Box
              className="w-[16px] h-[16px]"
              sx={{
                background: "#F8B409",
                borderRadius: "2px",
              }}
            ></Box>{" "}
            <span className="text-base">PUSD</span>{" "}
            <span className="text-sm">Target Supply: 1B</span>
          </div>
        </Box>
      ) : null} */}
    </Box>
  );
}
