import { BACKEND_HOST_URL } from "../config/backend";
import useSWR from "swr";
import { mainnet } from "wagmi/chains";
import { isEmpty } from "lodash";
import { useMemo } from "react";

export function toCamelCase(obj: any) {
  let result = obj;
  if (!result) {
    return result;
  } else if (typeof obj === "object") {
    if (obj instanceof Array) {
      result = obj.map(toCamelCase);
    } else {
      result = {};
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          const newKey = key.replace(/(_\w)/g, (k) => k[1].toUpperCase());
          result[newKey] = toCamelCase(obj[key]);
        }
      }
    }
  }
  return result;
}

const callFetcher = async (...args: Parameters<typeof fetch>) => {
  const response = await fetch(...args);
  const data = await response.json();
  return toCamelCase(data);
};

const sendFetcher = (url: string, params: Record<string, unknown>) => {
  return fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .then((r) => r.json())
    .then((r) => {
      return toCamelCase(r);
    });
};

const postFetcher = async (
  url: string,
  params: Record<string, unknown>,
  headers?: HeadersInit
) => {
  const response = await fetch(url, {
    method: "post",
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });
  const data = await response.json();
  return data;
};

export const usePoolsRequest = () => {
  const url = `${BACKEND_HOST_URL[mainnet.id].V2}/markets`;
  const {
    data,
    mutate,
    isValidating: isLoading,
  } = useSWR(url, callFetcher, {
    refreshInterval: 5000,
    refreshWhenHidden: true,
    refreshWhenOffline: true,
  });
  return {
    data: data?.data,
    isLoading,
    refetch: mutate,
  };
};

export const useTokensPrice = (addresses: Array<string> | null) => {
  const fetchedAddress = useMemo(() => {
    if (isEmpty(addresses)) {
      return [];
    }
    return addresses.filter((item) => item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses?.length]);
  const tokensPriceUrl = `${BACKEND_HOST_URL[mainnet.id].V2}/prices/24hr`;
  const {
    data,
    isValidating: isLoading,
    mutate,
  } = useSWR(
    !isEmpty(fetchedAddress) ? tokensPriceUrl : null,
    () => sendFetcher(tokensPriceUrl, { addresses }),
    {
      refreshInterval: 5000,
      refreshWhenHidden: true,
      refreshWhenOffline: true,
    }
  );
  return {
    tokensPrice: data?.data,
    fetchTokenPriceIsLoading: isLoading,
    fetchTokensPrice: mutate,
  };
};

export const useMultiTokens = (addresses: Array<string> | null) => {
  const fetchedAddress = useMemo(() => {
    if (isEmpty(addresses)) {
      return [];
    }
    return addresses.filter((item) => item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses?.length]);
  const multiTokensUrl = `${
    BACKEND_HOST_URL[mainnet.id].V2
  }/prices/by-addresses`;
  const {
    data,
    isValidating: isLoading,
    mutate,
  } = useSWR(
    !isEmpty(fetchedAddress) ? multiTokensUrl : null,
    () => sendFetcher(multiTokensUrl, { addresses }),
    {
      refreshInterval: 5000,
      refreshWhenHidden: true,
      refreshWhenOffline: true,
    }
  );
  return {
    tokensMultiPrice: data?.data,
    fetchMultiTokensIsLoading: isLoading,
    fetchPoolsMultiPrice: mutate,
  };
};
