import { useCallback, useEffect, useMemo } from "react";

import { Hash } from "@wagmi/core";
import ERC20ABI from "../../config/abis/ERC20.json";
import ReaderAbi from "../../config/abis/Reader.json";
import { getContractAddressV2 } from "../../config/contracts";
import { useAccount } from "wagmi";

import { BigNumber } from "ethers";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setLpt } from "./index";
import { selectPools } from "../pools/slice";
import { createMulticall, formatUnits, parseUnits } from "../../utils";
import { ethers } from "ethers";
import { mainnet } from "../../config/constants/connector/chains";

export default function Updater() {
  const pools = useAppSelector(selectPools);
  const dispatch = useAppDispatch();
  const provider = new ethers.providers.JsonRpcProvider(
    mainnet?.rpcUrls?.public.http[0]
  );
  const { multicallv3 } = createMulticall(provider);
  const { address, isConnected } = useAccount();
  const ReaderAddress = getContractAddressV2(mainnet.id, "Reader");

  const contracts = useMemo(() => {
    if (!pools) {
      return undefined;
    }
    return [pools[0]?.lptToken].map((token: string) => {
      return [
        {
          address: token as Hash,
          abi: ERC20ABI,
          name: "name",
        },
        {
          address: token as Hash,
          abi: ERC20ABI,
          name: "symbol",
        },
        {
          address: token as Hash,
          abi: ERC20ABI,
          name: "decimals",
        },
        {
          address: token as Hash,
          abi: ERC20ABI,
          name: "totalSupply",
        },
        {
          address: ReaderAddress as Hash,
          abi: ReaderAbi.abi,
          name: "calcLPTPrice",
          params: [
            pools[0]?.address,
            BigNumber.from(parseUnits(pools[0]?.indexPrice, 10)),
          ],
        },
      ];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pools, address, isConnected]);

  const fetchLptInformation = useCallback(async () => {
    if (!contracts) {
      return;
    }
    try {
      const data = await multicallv3({
        calls: contracts ? contracts.flat(2) : [],
        chainId: mainnet.id,
      });

      if (data) {
        const refactorData = [pools[0]?.lptToken].reduce((all, item, index) => {
          const tokenData = data.slice(index * 5, (index + 1) * 5);
          const LPTPrice = formatUnits(tokenData[4]?.price.toString(), 10);

          return {
            ...all,
            [item]: {
              address: item,
              name: tokenData[0].toString() || "",
              symbol: `PLP`,
              decimals: tokenData[2].toString(),
              totalSupply: formatUnits(
                tokenData[3]?.toString(),
                tokenData[2].toString()
              ),
              LPTPrice,
            },
          };
        }, {});
        if (refactorData) {
          dispatch(setLpt(refactorData));
        }
      }
    } catch (e: any) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multicallv3, contracts, address]);

  useEffect(() => {
    fetchLptInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pools]);
  return null;
}
