import { Box, Button, Typography } from "@mui/material";
import { useAppBreakpoints } from "../../hooks/useAppBreakpoints";
import { FluidBg } from "color4bg.js";
import { useEffect } from "react";
import { StyleContainer } from "components/Common/StyleContainer";

const Banner = (props: any) => {
  // const theme = useTheme();
  // const { address, isConnected } = useAccount();
  // const matchSmall = useMediaQuery("(max-width: 768px)");
  const { isMatchMobile } = useAppBreakpoints();

  useEffect(() => {
    let obj = new FluidBg({
      dom: "box",
      colors: [
        "#36dd4a",
        "#a700f5",
        "#1100ff",
        "#000399",
        "#00cc8f",
        "#7214ff",
      ],
      loop: true,
    });
    const handleResize = () => {
      obj.resize();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // setColorbg(obj);
  }, []);
  return (
    <Box>
      <StyleContainer className="flex-col mt-2 sm:mt-0">
        <div className="relative">
          <div className="h-[400px] sm:h-[500px] relative z-20 flex flex-col justify-center items-center">
            <Typography
              variant="h1"
              className="text-[40px] leading-[60px] fontBold text-center sm:text-[32px] "
              color="white"
            >
              Purely Decentralized Money
            </Typography>
            <Button
              className="mt-1 underline underline-offset-4"
              // onClick={handleButtonClick}
              href="https://docs.pure.cash/pure.cash"
              target="_blank"
              variant="text"
              size="large"
              sx={{
                color: "#fff",
                padding: "12px 48px",
                fontWeight: 400,
                height: 54,
                borderRadius: 48,
                fontSize: isMatchMobile ? `16px !important` : 20,
              }}
            >
              Learn about Pure.cash
            </Button>
          </div>
          <div
            id="box"
            className="w-full h-[400px] sm:h-[500px]  rounded-[30px] absolute top-0 left-0 z-10"
          ></div>
        </div>
      </StyleContainer>
    </Box>
  );
};

export default Banner;
