export const POOL_INIT_CODE_HASH =
  "0xe34f199b19b2b4f47f68442619d555527d244f78a3297ea89325f843f87b8b54";

export const ARBITRUM_ONE_BLOCKS_ADDRESS =
  "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks";

export const DEFAULT_POOL_ADDRESS_FOR_ARBITRUM_ONE = [
  "0xc7f83c20ad7DA85661546FECC19C27cb22ED53E3",
  "0x7671e24bBD8B1FD52A4d936C8170927Fa36BAE3A",
  "0xaFF6b531F5a06c00A21e34FD7BCe9cf024ef45F0",
  "0xC0cF0f380DdB44dBCAF19A86d094C8BBA3efa04a",
];

export const APP_LINK = "https://app.pure.cash";
