import { styled } from "@mui/material";

export const StyleContainer = styled("div")(({ theme }) => ({
  "@media screen and (max-width: 768px)": {
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    // paddingTop: "1.5rem",
    width: "100%",
  },
  // "@media screen and (min-width: 769px) and (max-width: 999px)": {
  //   padding: "0px 16px",
  //   width: "100%",
  // },
  // "@media screen and (min-width: 1000px) and (max-width: 1327px)": {
  //   padding: "0 24px",
  //   width: "100%",
  // },

  "@media screen and (min-width: 1000px)": {
    margin: "0 auto",
    padding: 0,
    width: "986px",
  },
}));
