import { useEffect, useState } from "react";
import Logo from "../../components/logo";
import cn from "classnames";
import Navs from "./navs";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";

import CloseIcon from "@mui/icons-material/Close";
import { Box, useMediaQuery } from "@mui/material";
import classNames from "classnames";
// import { useAppBreakpoints } from "hooks/useAppBreakpoints";
import { useLocation } from "react-router-dom";
import { StyleContainer } from "components/Common/StyleContainer";
// import { StyledButton } from "../../components/styled";
// import { useAccount } from "wagmi";
// import { useCheckLogin, useCurrentChain } from "hooks/useCurrentChain";
// import ConnectWalletButton from "components/Wallet/ConnectWalletButton";
// import SwitchNetworkButton from "components/Wallet/SwitchNetworkButton";
// import { useLocation } from "react-router-dom";
// import Account from "./Account";
// import ErrorOutline from "@mui/icons-material/ErrorOutline";

const StyleDrawer = styled(Drawer)<any>(({ theme, match }) => ({
  width: "100%",
  ".MuiDrawer-paper": {
    borderRadius: 0,
    width: !match ? "375px" : "100%",
    background: "#141519",
    padding: `${theme.spacing(2)} 22px`,
    overflow: "hidden",
  },
}));

// const NavsContainer = styled("div")(({ theme }) => ({
//   height: 80,
//   "@media screen and (max-width: 1000px)": {
//     padding: `0 16px`,
//   },
//   "@media screen and (max-width: 768px)": {
//     padding: `0 12px`,
//   },
// }));

const Header = (props: any) => {
  const location = useLocation();

  useEffect(() => {
    setShowModal(false);
  }, [location]);
  // let location = useLocation();
  // let path = location.pathname.replace("/", "");
  // const { isConnected } = useAccount();
  // const { isSupportedChain } = useCurrentChain();
  // const { isMatchMobile } = useAppBreakpoints();
  const [showModal, setShowModal] = useState(false);
  const matchSmall = useMediaQuery("(max-width: 768px)");

  const matchQuery = useMediaQuery("(min-width: 1000px)");
  const matchSmallNav = useMediaQuery("(max-width: 1000px)");
  // const theme = useTheme();

  useEffect(() => {
    if (!matchQuery) {
      return;
    }
    setShowModal(false);
  }, [matchQuery]);

  return (
    <div className="relative z-50">
      <StyleContainer
        className={cn("flex items-center justify-between h-[80px] sm:h-[86px]")}
      >
        <div className="flex items-center">
          <Logo size={matchSmall ? 123 : 170} height={matchSmall ? 26 : 36} />
        </div>
        <div className="flex items-center sm:justify-end"></div>
        <Box className={cn("flex items-center")}>
          <Box className={cn(matchSmallNav ? "hidden" : "block")}>
            <Navs />
          </Box>
          {/*  <Button
            component="a"
            // className="px-3"
            variant="contained"
            size="large"
            // fullWidth={isMatchMobile}
            // href="#"
            // target="_blank"
            sx={{
              color: alpha("#000", 0.9),
              padding: isMatchMobile ? "6px 12px" : "6px 24px",
              background:
                "linear-gradient(64deg, #03AAF7 32.22%, #02F1A0 93.46%)",
              fontWeight: 600,
              height: isMatchMobile ? 26 : 40,
              borderRadius: isMatchMobile ? 35 : 35,
              fontSize: isMatchMobile ? `12px !important` : 16,
              // "&:hover": {
              //   backgroundColor: "rgba(3,163,255,0.8) !important",
              //   color: alpha("#000", 1),
              // },
            }}
          >
            COMING SOON
          </Button> */}
          <Box
            className={cn(
              "cursor-pointer ml-0.75",
              matchSmallNav ? "block space-x-0.5" : "hidden"
            )}
          >
            <MenuIcon
              onClick={() => setShowModal(true)}
              sx={{
                fontSize: 26,
              }}
            />
          </Box>
        </Box>

        <StyleDrawer
          open={showModal}
          anchor="right"
          match={matchSmall}
          sx={{
            ".MuiPaper-root": {
              height: matchSmall ? window.innerHeight : "100vh",
              background: "#fff",
            },
          }}
        >
          <div className="bg"></div>
          <div className="bg2"></div>
          <div className="relative z-9999 flex flex-col gap-y-2">
            <div
              className={classNames("flex items-center", {
                "justify-between": matchSmall,
                "justify-end": !matchSmall,
              })}
            >
              <Logo
                className={classNames({
                  hidden: !matchSmall,
                })}
                size={matchSmall ? 123 : 170}
                onClick={() => setShowModal(false)}
              />
              <CloseIcon
                className="cursor-pointer"
                onClick={() => setShowModal(false)}
                sx={{ fontSize: 24 }}
              />
            </div>
            <div
              className={classNames("flex-grow flex flex-col sm:mt-1")}
              style={{ height: window.innerHeight - 85 }}
            >
              <Navs onClick={() => setShowModal(false)} drawer={true} />
            </div>
          </div>
        </StyleDrawer>
      </StyleContainer>
    </div>
  );
};

export default Header;
