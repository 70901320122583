import { Box, Card, Typography } from "@mui/material";
import { StyleContainer } from "components/Common/StyleContainer";
import type { ReactElement } from "react";
import Banner from "../../assets/longonly/banner.svg";
import IconEth from "../../assets/longonly/icon-eth.svg";
import IconUsd from "../../assets/longonly/icon-usd.svg";

export default function DetailsPage(): ReactElement {
  return (
    <Box className="z-200 relative sm:overflow-hidden">
      <StyleContainer className="sm:mt-[40px]">
        <Box className="relative">
          <Box className="mt-[40px] sm:mt-[8px] sm:pl-[8px]">
            <Typography
              variant="h1"
              fontWeight={600}
              className="text-[40px] leading-[60px] sm:text-[26px] sm:leading-[42px] ph:text-[30px]"
            >
              Long ETH
            </Typography>
            <Typography
              variant="h1"
              fontWeight={600}
              className="text-[40px] leading-[60px] sm:text-[26px] sm:leading-[42px] ph:text-[30px]"
            >
              With Zero Holding Costs
            </Typography>
          </Box>
        </Box>
        <Box className="relative mt-[40px] flex h-[262px] justify-center sm:mt-[20px] sm:h-[180px]">
          <img src={Banner} alt="Banner" className=" h-full w-[85%]" />
        </Box>

        <Box className="mt-[80px] flex justify-between space-x-[12px] sm:mt-[40px] sm:flex-col sm:space-x-0 sm:space-y-[20px]">
          <Card className="h-[364px] flex-1 space-y-[24px] rounded-[20px] p-[32px] sm:w-full sm:space-y-[16px] sm:rounded-[14px] sm:p-[16px]">
            <Typography
              variant="h2"
              fontWeight={600}
              className="text-[32px] sm:text-[24px]"
            >
              Zero Holding Costs
            </Typography>
            <Typography
              variant="h5"
              color="text.secondary"
              className="leading-[28px] text-[18px] sm:text-[16px]"
            >
              Hold ETH long positions with zero borrow or funding fees, just
              like holding spot, but with leverage up to 10x.
            </Typography>
            <Box className="flex justify-end sm:mt-[28px]">
              <img
                src={IconUsd}
                alt=""
                className="h-[130px] w-[130px] sm:h-[92px] sm:w-[92px]"
              />
            </Box>
          </Card>

          <Card className="h-[364px] flex-1 space-y-[24px] rounded-[20px] p-[32px] sm:w-full sm:space-y-[16px] sm:rounded-[14px] sm:p-[16px]">
            <Typography
              variant="h2"
              fontWeight={600}
              className="text-[32px] sm:text-[24px]"
            >
              Built on Ethereum
            </Typography>
            <Typography
              variant="h5"
              color="text.secondary"
              className="leading-[28px] text-[18px] sm:text-[16px]"
            >
              Fully on-chain, leveraging Ethereum’s robust security and vast
              ecosystem for trustworthy trading.
            </Typography>
            <Box className="flex justify-end sm:mt-[28px]">
              <img
                src={IconEth}
                alt=""
                className="h-[130px] w-[130px] sm:h-[92px] sm:w-[92px]"
              />
            </Box>
          </Card>
        </Box>
      </StyleContainer>
    </Box>
  );
}
