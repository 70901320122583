import { Box, Card, Container, Typography, alpha } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import BgCardLeft from "../../assets/pusd/bgCardLeft.svg";
import BgCardRight from "../../assets/pusd/bgCardRight.svg";
import BannerGrid1 from "../../assets/pusd/bannerGrid1.svg";
import BannerGrid2 from "../../assets/pusd/bannerGrid2.svg";
import BannerGrid3 from "../../assets/pusd/bannerGrid3.svg";
import classNames from "classnames";
import { StyleContainer } from "components/Common/StyleContainer";

function Banner() {
  const matchSmall = useMediaQuery("(max-width: 768px)");
  return (
    <Box className="relative w-full">
      <StyleContainer className="space-y-[80px] sm:space-y-[40px] mt-[58px] sm:mt-[20px]">
        <Box className="sm:pl-[8px]">
          <Typography
            className="text-[40px] sm:text-[26px]"
            fontWeight={600}
            color="text.primary"
          >
            Stable, perpetually.
          </Typography>
          <Typography
            className="text-[20px] sm:text-[18px] mt-[20px]"
            color="text.secondary"
          >
            Pure USD (PUSD) is a fully {matchSmall ? <br /> : null}{" "}
            decentralized, delta-neutral stablecoin
            <br />
            built on Ethereum.
          </Typography>
        </Box>

        <Box
          className={classNames("", {
            "grid grid-cols-3 gap-[20px]": !matchSmall,
            "space-y-[20px]": matchSmall,
          })}
        >
          {[
            {
              title: "Perpetual Stability",
              text: "Backed by ETH with equal short positions, PUSD ensures perpetual stability.",
              src: BannerGrid1,
            },
            {
              title: "Full Decentralization",
              text: "Fully deployed on Ethereum, PUSD enables free, open, censorship-resistant, and unstoppable financial interactions.",
              src: BannerGrid2,
            },
            {
              title: "Max Capital Efficiency",
              text: "Under full asset collateralization, the delta-neutral model provides maximum capital efficiency.",
              src: BannerGrid3,
            },
          ].map((item, index) => {
            return (
              <Card className="px-[22px] py-[26px] sm:p-[28px] sm:space-y-0 h-[330px] rounded-[20px] sm:h-auto">
                <Typography className="text-[20px] mb-[16px]" fontWeight={600}>
                  {item.title}
                </Typography>
                <Typography
                  className="text-[16px] sm:text-[18px] whitespace-normal break-words sm:mt-1"
                  color="text.secondary"
                >
                  {item.text}
                </Typography>
                <Box className="text-right">
                  <img
                    src={item.src}
                    alt=""
                    className={classNames(
                      "text-right mr-[18px] w-[120px] sm:w-[110px]",
                      {
                        "mt-[32px] ": index === 0,
                        "mt-[20px]": index === 1,
                        "mt-[45px]": index === 2,
                      }
                    )}
                  />
                </Box>
              </Card>
            );
          })}
        </Box>
      </StyleContainer>
    </Box>
  );
}

export default Banner;
