import { Reducer, createSlice } from "@reduxjs/toolkit";
import { Hash } from "@wagmi/core";

export interface TokenProps {
  address: Hash;
  name: string;
  symbol: string;
  decimals: number;
  totalSupply: number;
  price: string | number;
}

interface StateProps {
  tokens: {
    [key: Hash]: TokenProps;
  };
}

const initialState: StateProps = {
  tokens: {},
};

export const slice = createSlice({
  name: "lpt",
  initialState,
  reducers: {
    setLpt(state, { payload }) {
      state.tokens = {
        ...state.tokens,
        ...payload,
      };
    },
  },
});

export const { setLpt } = slice.actions;

export default slice.reducer as Reducer<typeof initialState>;
