import { StyleContainer } from "components/Common/StyleContainer";
import Card1Icon from "../../assets/index/card_1.svg";
import Card2Icon from "../../assets/index/card_2.svg";
import { Box, Typography } from "@mui/material";
import { useAppBreakpoints } from "hooks/useAppBreakpoints";
import Blogs from "./Blogs";
import Vision from "./Vision";
import HtmlRenderer from "./HtmlRenderer";
import Brand from "./Brand";

function Cards() {
  const { isMatchMobile } = useAppBreakpoints();
  const data = [
    {
      pic: Card1Icon,
      title: "For DeFi Believers",
      context:
        "<strong>Pure USD (PUSD)</strong> is a fully on-chain, delta-neutral stablecoin backed by ETH with equal short positions, ensuring perpetual stability.",
      href: "/pusd",
    },
    {
      pic: Card2Icon,
      title: "For Bull Believers",
      context:
        "<strong>LongOnly</strong> is a perpetual exchange with zero funding rates, allowing traders to hold ETH long positions at no cost, with up to 10x leverage.",
      href: "/longonly",
    },
  ];
  return (
    <Box>
      <Blogs />
      <StyleContainer className="mt-[80px] sm:mt-[40px]">
        <Typography
          className="mb-[30px] leading-[1.5] sm:mb-[20px]"
          fontSize={isMatchMobile ? 20 : 32}
          fontWeight={600}
        >
          Products
        </Typography>
        <div className="grid grid-cols-2 w-full gap-[18px] sm:block sm:space-y-[12px]">
          {data.map((item, index) => (
            <Box
              key={index}
              className=" rounded-[20px] p-[24px] sm:p-[12px] sm:rounded-[14px]"
              sx={{
                background: "#F7F7F6",
                border: "1px solid #F7F7F6",
                "&:hover": {
                  border: "1px solid rgba(78, 78, 78, 0.70)",
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                window.open(item.href, isMatchMobile ? "_self" : "_blank");
              }}
            >
              <Typography
                className="leading-[1.5] mt-[20px]"
                fontSize={isMatchMobile ? 14 : 18}
                fontWeight={400}
                color="text.secondary"
              >
                <HtmlRenderer htmlString={item.context} />
              </Typography>
              <Box className="flex justify-end mt-[45px] mb-[16px] mr-[16px] sm:m-0 sm:mt-[20px]">
                <img
                  src={item.pic}
                  alt="Card1Icon"
                  className="h-[182px] sm:h-[150px]"
                />
              </Box>
            </Box>
          ))}
        </div>
      </StyleContainer>
      <Brand />
      <Vision />
    </Box>
  );
}

export default Cards;
