import { Box } from "@mui/material";
import Banner from "./Banner";
// import Mechanism from "./Mechanism";
import Principle from "./Principle";

function Pusd() {
  return (
    <>
      <Box>
        <Banner />
        {/* <Mechanism /> */}
        <Principle />
      </Box>
    </>
  );
}

export default Pusd;
