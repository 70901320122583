import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const StyledLoading = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // color: alpha(theme.palette.primary.main, 0.6)
  background: theme.palette.background.default,
}));

const Loading = (props: any) => {
  return (
    <StyledLoading>
      <svg
        width="465"
        height="465"
        viewBox="0 0 465 465"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="232.499"
          cy="232.5"
          r="145.431"
          stroke="#21506A"
          stroke-width="0.846995"
        >
          <animate
            attributeName="opacity"
            to="0"
            begin="0s"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
        <circle
          opacity="0.8"
          cx="232.5"
          cy="232.5"
          r="164.926"
          stroke="#21506A"
          stroke-width="0.846995"
        >
          <animate
            attributeName="opacity"
            to="0"
            begin="0.1s"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
        <circle
          opacity="0.6"
          cx="232.499"
          cy="232.5"
          r="184.421"
          stroke="#21506A"
          stroke-width="0.846995"
        >
          <animate
            attributeName="opacity"
            to="0"
            begin="0.2s"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
        <circle
          opacity="0.4"
          cx="232.501"
          cy="232.5"
          r="205.361"
          stroke="#21506A"
          stroke-width="0.846995"
        >
          <animate
            attributeName="opacity"
            to="0"
            begin="0.3s"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
        <circle
          opacity="0.2"
          cx="232.5"
          cy="232.5"
          r="232.077"
          stroke="#21506A"
          stroke-width="0.846995"
        >
          <animate
            attributeName="opacity"
            to="0"
            begin="0.4s"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
        <g>
          <path
            d="M211.797 184.439L243.368 154.593C244.667 153.364 246.811 154.162 246.992 155.941L250.269 188.206C250.356 189.059 249.928 189.882 249.181 190.302L216.227 208.816C214.865 209.581 213.166 208.687 213.025 207.131L211.131 186.199C211.072 185.542 211.317 184.893 211.797 184.439Z"
            fill="url(#paint0_linear_25956_44108)"
          ></path>
          <mask
            id="mask0_25956_44108"
            maskUnits="userSpaceOnUse"
            x="211"
            y="154"
            width="40"
            height="56"
          >
            <path
              d="M211.797 184.44L243.368 154.594C244.667 153.365 246.811 154.163 246.992 155.942L250.269 188.207C250.356 189.06 249.928 189.883 249.181 190.303L216.227 208.817C214.865 209.582 213.166 208.688 213.025 207.132L211.131 186.2C211.072 185.543 211.317 184.894 211.797 184.44Z"
              fill="url(#paint1_linear_25956_44108)"
            />
          </mask>
          <g mask="url(#mask0_25956_44108)">
            <path
              d="M247.189 151.702L213.436 208.589C212.397 210.34 214.141 212.422 216.047 211.706L257.354 196.191C258.33 195.825 258.904 194.812 258.716 193.787L251.162 152.415C250.804 150.454 248.205 149.988 247.189 151.702Z"
              fill="url(#paint2_linear_25956_44108)"
            />
          </g>
          <path
            d="M263.938 190.429L305.571 202.847C307.284 203.358 307.666 205.614 306.215 206.66L279.912 225.63C279.216 226.132 278.29 226.174 277.552 225.736L245.042 206.454C243.698 205.657 243.623 203.739 244.9 202.839L262.081 190.733C262.62 190.352 263.305 190.241 263.938 190.429Z"
            fill="url(#paint3_linear_25956_44108)"
          />
          <mask
            id="mask1_25956_44108"
            maskUnits="userSpaceOnUse"
            x="243"
            y="190"
            width="65"
            height="37"
          >
            <path
              d="M263.938 190.43L305.571 202.848C307.284 203.359 307.666 205.615 306.215 206.661L279.912 225.631C279.216 226.133 278.29 226.174 277.552 225.737L245.042 206.455C243.698 205.658 243.623 203.74 244.9 202.84L262.081 190.734C262.62 190.353 263.305 190.242 263.938 190.43Z"
              fill="url(#paint4_linear_25956_44108)"
            />
          </mask>
          <g mask="url(#mask1_25956_44108)">
            <path
              d="M309.985 204.711L243.843 203.924C241.807 203.9 240.876 206.451 242.449 207.744L276.539 235.759C277.344 236.421 278.508 236.412 279.302 235.737L311.354 208.508C312.873 207.218 311.977 204.735 309.985 204.711Z"
              fill="url(#paint5_linear_25956_44108)"
            />
          </g>
          <path
            d="M284.822 238.581L294.885 280.845C295.299 282.585 293.536 284.043 291.905 283.31L262.324 270.015C261.542 269.664 261.043 268.882 261.053 268.025L261.497 230.229C261.515 228.667 263.138 227.642 264.556 228.299L283.631 237.124C284.23 237.402 284.669 237.938 284.822 238.581Z"
            fill="url(#paint6_linear_25956_44108)"
          />
          <mask
            id="mask2_25956_44108"
            maskUnits="userSpaceOnUse"
            x="261"
            y="228"
            width="34"
            height="56"
          >
            <path
              d="M284.822 238.581L294.885 280.845C295.299 282.585 293.536 284.043 291.905 283.31L262.324 270.015C261.542 269.664 261.043 268.882 261.053 268.025L261.497 230.229C261.515 228.667 263.138 227.642 264.556 228.299L283.631 237.124C284.23 237.402 284.669 237.938 284.822 238.581Z"
              fill="url(#paint7_linear_25956_44108)"
            />
          </mask>
          <g mask="url(#mask2_25956_44108)">
            <path
              d="M295.478 285.6L263.089 227.926C262.092 226.151 259.417 226.62 259.084 228.628L251.867 272.159C251.696 273.188 252.286 274.191 253.268 274.541L292.874 288.685C294.751 289.355 296.454 287.338 295.478 285.6Z"
              fill="url(#paint8_linear_25956_44108)"
            />
          </g>
          <path
            d="M253.563 280.74L221.993 310.587C220.693 311.815 218.549 311.018 218.368 309.239L215.091 276.974C215.004 276.121 215.432 275.297 216.18 274.877L249.133 256.364C250.496 255.599 252.194 256.492 252.335 258.049L254.229 278.98C254.288 279.638 254.043 280.287 253.563 280.74Z"
            fill="url(#paint9_linear_25956_44108)"
          />
          <mask
            id="mask3_25956_44108"
            maskUnits="userSpaceOnUse"
            x="215"
            y="256"
            width="40"
            height="56"
          >
            <path
              d="M253.563 280.739L221.993 310.586C220.693 311.814 218.549 311.017 218.368 309.238L215.091 276.973C215.004 276.12 215.432 275.296 216.18 274.876L249.133 256.363C250.496 255.598 252.194 256.491 252.335 258.048L254.229 278.979C254.288 279.637 254.043 280.286 253.563 280.739Z"
              fill="url(#paint10_linear_25956_44108)"
            />
          </mask>
          <g mask="url(#mask3_25956_44108)">
            <path
              d="M218.172 313.478L251.925 256.59C252.963 254.84 251.219 252.758 249.314 253.473L208.006 268.989C207.03 269.355 206.457 270.368 206.644 271.393L214.199 312.765C214.557 314.725 217.155 315.191 218.172 313.478Z"
              fill="url(#paint11_linear_25956_44108)"
            />
          </g>
          <path
            d="M201.422 274.75L159.789 262.333C158.075 261.822 157.694 259.566 159.144 258.52L185.448 239.549C186.143 239.048 187.07 239.006 187.807 239.444L220.317 258.726C221.661 259.523 221.737 261.441 220.459 262.341L203.279 274.447C202.739 274.827 202.055 274.939 201.422 274.75Z"
            fill="url(#paint12_linear_25956_44108)"
          />
          <mask
            id="mask4_25956_44108"
            maskUnits="userSpaceOnUse"
            x="158"
            y="239"
            width="64"
            height="36"
          >
            <path
              d="M201.422 274.749L159.789 262.332C158.075 261.821 157.694 259.565 159.144 258.519L185.448 239.548C186.143 239.047 187.07 239.005 187.807 239.443L220.317 258.725C221.661 259.522 221.737 261.44 220.459 262.34L203.279 274.446C202.739 274.826 202.055 274.938 201.422 274.749Z"
              fill="url(#paint13_linear_25956_44108)"
            />
          </mask>
          <g mask="url(#mask4_25956_44108)">
            <path
              d="M155.374 260.468L221.517 261.256C223.552 261.28 224.483 258.729 222.911 257.436L188.82 229.42C188.015 228.758 186.851 228.768 186.057 229.443L154.005 256.671C152.486 257.961 153.382 260.445 155.374 260.468Z"
              fill="url(#paint14_linear_25956_44108)"
            />
          </g>
          <path
            d="M180.54 226.599L170.478 184.335C170.063 182.595 171.826 181.137 173.457 181.87L203.038 195.164C203.82 195.516 204.32 196.298 204.31 197.155L203.866 234.951C203.847 236.513 202.224 237.537 200.806 236.881L181.732 228.055C181.132 227.778 180.693 227.241 180.54 226.599Z"
            fill="url(#paint15_linear_25956_44108)"
          />
          <mask
            id="mask5_25956_44108"
            maskUnits="userSpaceOnUse"
            x="170"
            y="181"
            width="35"
            height="57"
          >
            <path
              d="M180.539 226.6L170.477 184.336C170.062 182.596 171.825 181.138 173.456 181.871L203.037 195.165C203.819 195.517 204.319 196.299 204.309 197.156L203.865 234.952C203.846 236.514 202.223 237.538 200.805 236.882L181.731 228.056C181.131 227.779 180.692 227.242 180.539 226.6Z"
              fill="url(#paint16_linear_25956_44108)"
            />
          </mask>
          <g mask="url(#mask5_25956_44108)">
            <path
              d="M169.883 179.581L202.273 237.255C203.269 239.03 205.944 238.561 206.277 236.552L213.495 193.021C213.665 191.993 213.075 190.99 212.094 190.64L172.487 176.496C170.61 175.826 168.908 177.843 169.883 179.581Z"
              fill="url(#paint17_linear_25956_44108)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="360 232.5 232.5"
            to="0 232.5 232.5"
            dur="8s"
            repeatCount="indefinite"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_25956_44108"
            x1="247.047"
            y1="149.827"
            x2="201.706"
            y2="192.9"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCA9FB" />
            <stop offset="1" stop-color="#3EFFF3" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_25956_44108"
            x1="217.01"
            y1="207.75"
            x2="251.376"
            y2="159.121"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0085D1" />
            <stop offset="1" stop-color="#03FF77" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_25956_44108"
            x1="219.038"
            y1="189.875"
            x2="256.446"
            y2="180.266"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4C69FF" />
            <stop offset="1" stop-color="#FEACFF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_25956_44108"
            x1="311.538"
            y1="203.651"
            x2="251.565"
            y2="185.92"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCA9FB" />
            <stop offset="1" stop-color="#3EFFF3" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_25956_44108"
            x1="246.357"
            y1="206.6"
            x2="305.654"
            y2="212.047"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0085D1" />
            <stop offset="1" stop-color="#03FF77" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_25956_44108"
            x1="262.85"
            y1="199.419"
            x2="289.877"
            y2="227.011"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4C69FF" />
            <stop offset="1" stop-color="#FEACFF" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_25956_44108"
            x1="297.172"
            y1="286.414"
            x2="282.541"
            y2="225.612"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCA9FB" />
            <stop offset="1" stop-color="#3EFFF3" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_25956_44108"
            x1="262.029"
            y1="231.44"
            x2="286.959"
            y2="285.517"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0085D1" />
            <stop offset="1" stop-color="#03FF77" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_25956_44108"
            x1="276.494"
            y1="242.134"
            x2="266.112"
            y2="279.336"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4C69FF" />
            <stop offset="1" stop-color="#FEACFF" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_25956_44108"
            x1="218.313"
            y1="315.352"
            x2="263.654"
            y2="272.28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCA9FB" />
            <stop offset="1" stop-color="#3EFFF3" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_25956_44108"
            x1="248.35"
            y1="257.429"
            x2="213.984"
            y2="306.058"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0085D1" />
            <stop offset="1" stop-color="#03FF77" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_25956_44108"
            x1="246.323"
            y1="275.304"
            x2="208.914"
            y2="284.914"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4C69FF" />
            <stop offset="1" stop-color="#FEACFF" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_25956_44108"
            x1="153.822"
            y1="261.529"
            x2="213.794"
            y2="279.259"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCA9FB" />
            <stop offset="1" stop-color="#3EFFF3" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_25956_44108"
            x1="219.002"
            y1="258.58"
            x2="159.705"
            y2="253.132"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0085D1" />
            <stop offset="1" stop-color="#03FF77" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_25956_44108"
            x1="202.509"
            y1="265.761"
            x2="175.482"
            y2="238.169"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4C69FF" />
            <stop offset="1" stop-color="#FEACFF" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_25956_44108"
            x1="168.19"
            y1="178.765"
            x2="182.821"
            y2="239.568"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCA9FB" />
            <stop offset="1" stop-color="#3EFFF3" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_25956_44108"
            x1="203.333"
            y1="233.74"
            x2="178.402"
            y2="179.664"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0085D1" />
            <stop offset="1" stop-color="#03FF77" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_25956_44108"
            x1="188.867"
            y1="223.047"
            x2="199.249"
            y2="185.845"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4C69FF" />
            <stop offset="1" stop-color="#FEACFF" />
          </linearGradient>
        </defs>
      </svg>
    </StyledLoading>
  );
};

export const SectionLoading = () => {
  return <CircularProgress />;
};

export default Loading;
