import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import BaseLayout from "../layout";
import Index from "../pages/index";
import NotFound from "../pages/404/index";
// import About from "../pages/about";
import Pusd from "pages/pusd";
import Longonly from "pages/Longonly";
import Launch from "pages/launch";
import { LaunchContextProvider } from "../context/LaunchContext";

const AppRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<BaseLayout />}>
        <Route path="/" element={<Index />} />
        <Route
          path="/launch"
          element={
            <LaunchContextProvider>
              <Launch />
            </LaunchContextProvider>
          }
        />
        <Route path="/pusd" element={<Pusd />} />
        <Route path="/longonly" element={<Longonly />} />
        {/* <Route path="/about" element={<About />} /> */}
      </Route>
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
};

export default AppRouter;
