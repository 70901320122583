import "@fontsource/noto-sans";
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/600.css";
import "@fontsource/noto-sans/700.css";

import "@fontsource/inter";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import { darken, alpha, createTheme, Theme } from "@mui/material/styles";
import { Padding } from "@mui/icons-material";

interface ColorsProps {
  dividerColor: string;
  primaryColor: string;
  secondaryColor: string;
  textSecondary: string;
  paperBg: string;
  defaultBg: string;
  actionHover: string;
  textPrimary: string;
  helpers: {
    background: {
      paper: string;
    };
  };
}

interface ThemeConfigProps {
  [key: string]: ColorsProps;
}

const themeConfig: ThemeConfigProps = {
  dark: {
    dividerColor: "rgba(78, 80, 108 ,0.5)",
    primaryColor: "#03A4FF",
    secondaryColor: "#F8B409",
    paperBg: alpha("#1E2126", 0.8),
    defaultBg: "#111",
    actionHover: "rgba(255, 255, 255, 0.05)",
    textPrimary: alpha("#FFF", 0.9),
    textSecondary: "#8B919D",
    helpers: {
      background: {
        paper: "#292B30",
      },
    },
  },
  light: {
    dividerColor: "rgba(116, 118, 171,0.16)",
    primaryColor: "#03A4FF",
    secondaryColor: "#F8B409",
    paperBg: "#F7F7F6",
    defaultBg: "#FFF",
    actionHover: "rgba(135, 163, 217,0.1)",
    textPrimary: "#000",
    textSecondary: "#666",
    helpers: {
      background: {
        paper: "#292B30",
      },
    },
  },
};

export default function themeConfigs(mode: string) {
  const {
    dividerColor,
    primaryColor,
    textSecondary,
    paperBg,
    defaultBg,
    actionHover,
    textPrimary,
    secondaryColor,
    helpers,
  } = themeConfig[mode];
  const boxShadow = "0px 2px 20px rgba(0, 0, 0, 0.2)";
  const theme: Theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024.05,
        xl: 1200,
      },
    },
    palette: {
      primary: {
        main: primaryColor,
        dark: darken(primaryColor, 0.3),
        contrastText: "#18191E",
      },
      secondary: {
        main: secondaryColor,
        contrastText: "#000",
      },
      background: {
        default: defaultBg,
        paper: paperBg,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
        disabled: "rgba(152,153,179,1)",
      },
      error: {
        main: "#FE385C",
      },
      success: {
        main: "#0CBA71",
      },
      warning: {
        main: "#FFB800",
        dark: "#E0A200",
      },
      info: {
        main: "#4499CE",
      },
      action: {
        disabled: "rgba(152,153,179,0.4)",
        disabledBackground: "rgba(43,46,69,0.5)",
        selectedOpacity: 0.5,
        hover: actionHover,
      },
      divider: dividerColor,
    },
    helpers,
    typography: {
      fontFamily: "Inter",
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    },
    components: {
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 24,
            height: 24,
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            inherit: "div",
            body1: "div",
            body2: "div",
          },
        },
        styleOverrides: {
          root: {
            fontWeight: 400,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          filled: {
            height: "auto",
            padding: `4px 8px !important`,
            background: alpha(textSecondary, 0.3),
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            fontSize: 16,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: defaultBg,
            padding: "8px 10px",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            maxWidth: 420,
            color: textPrimary,
            backgroundColor: paperBg,
            fontSize: "13px",
            padding: "6px 16px",
            fontWeight: 400,
            filter: "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.14))",
            "@media screen and (max-width: 768px)": {
              maxWidth: 320,
            },
          },
          arrow: {
            color: helpers.background.paper,
          },
          tooltipPlacementTop: {
            marginBottom: "8px !important",
          },
          tooltipPlacementBottom: {
            marginTop: "10px !important",
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(paperBg, 0.4),
            borderRadius: 8,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: 0,
            padding: `0px 4px`,
          },
          head: {
            // backgroundColor: paperBg,
            padding: `12px 0 8px`,
            color: textSecondary,
            fontSize: 12,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: 300,
            padding: "16px",
            boxSizing: "border-box",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            position: "relative",
            borderRadius: 24,
            backgroundColor: "#F7F7F6",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "24px",
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {},
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: paperBg,
            borderRadius: 24,
            boxShadow: "none",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            // width: 1000
            "@media screen and (min-width: 768px)": {
              paddingLeft: "16px",
              paddingRight: "16px",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 24,
          },
          scrollButtons: {
            width: "auto",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            fontSize: 14,
            fontWeight: 400,
            borderRadius: "4px",
            fontFamily: "Inter",
            "&.themed": {
              background: "linear-gradient(90deg, #005EED 0%, #00C9BD 100%)",
              boxShadow: "1px 2px 10px rgba(42, 121, 240, 0.35) !important",
              borderRadius: "36px",
              lineHeight: 1,
              "&:hover": {
                opacity: 0.9,
              },
            },
            "@media screen and (max-width: 768px)": {
              fontSize: 12,
            },
          },
          outlined: {
            "&:hover": {
              borderColor: primaryColor,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: "auto !important",
            maxWidth: "auto",
            padding: "12px 24px",
            textTransform: "capitalize",
            minHeight: "auto",
          },
          textColorPrimary: {
            color: textSecondary,
            "&.Mui-selected": {
              fontFamily: "Inter",
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {},
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            padding: [8, 16],
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: textPrimary,
            minWidth: "28px !important",
            // justifyContent: 'flex-end'
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          gutters: {
            paddingLeft: 8,
            paddingRight: 8,
          },
        },
      },
      MuiCollapse: {
        styleOverrides: {
          root: {
            border: `1px solid ${dividerColor}`,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            margin: 0,
            minWidth: 360,
            width: 420,
            "@media screen and (max-width: 768px)": {
              width: "85%",
            },
          },
          paperWidthSm: {
            maxWidth: 750,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "16px 24px",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          fontSizeSmall: {
            fontSize: "1rem",
          },
        },
      },
      // MuiOutlinedInput: {
      //   styleOverrides: {
      //     root: {
      //       border: `1px solid ${dividerColor}`,
      //       "&:hover": {
      //         border: `1px solid ${dividerColor}`,
      //       },
      //     },
      //   },
      // },
      MuiSelect: {
        styleOverrides: {
          select: {
            minHeight: "auto",
            border: "0 none",
          },
          filled: {},
          outlined: {
            borderRadius: 32,
          },
          icon: {
            right: 8,
            color: textPrimary,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: "0.875rem",
            padding: "0.6rem 1.25rem",
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            background: helpers.background.paper,
            boxShadow,
            borderRadius: "4px",
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            backgroundColor: defaultBg,
          },
          grouped: {
            color: textSecondary,
            margin: "4px",
            padding: `4px 8px`,
            border: 0,
            "&.Mui-selected": {
              color: textPrimary,
              backgroundColor: paperBg,
              "&:hover": {
                backgroundColor: paperBg,
              },
            },
            "&.Mui-disabled": {
              border: 0,
            },
            "&:not(:first-of-type)": {
              borderRadius: "8px",
            },
            "&:first-of-type": {
              borderRadius: "8px",
              marginRight: 0,
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            // '& .MuiSwitch-track': {
            //   backgroundColor: darken(defaultBg, 0.4),
            // }
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            background: "none",
            padding: "6px 0",
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: textSecondary,
          },
          text: {
            fill: paperBg,
          },
        },
      },
    },
  });
  return theme;
}
