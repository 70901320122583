import { useMemo } from "react";
import { StyleContainer } from "components/Common/StyleContainer";
import { usePoolsFetch } from "../../fetch/usePoolsFetch";
import { toDecimalPlaces, multipliedBy } from "../../utils";
import { useAppSelector } from "../../state/hooks";
import { selectTokenByAddress } from "../../state/lpt/selector";
import Step from "./step";
import StepsChart from "./stepsChart";
import Summary from "./summary";

export default function Launch() {
  const { poolList } = usePoolsFetch();
  const pools = useAppSelector((state) => state.pools.pools);
  const lpt = useAppSelector(selectTokenByAddress(pools[0]?.lptToken));
  const summaryData = useMemo(() => {
    if (!poolList || !lpt) {
      return {
        LongOpenInterest: {
          value: "",
          usdValue: "",
        },
        PLP: {
          value: "",
          usdValue: "",
        },
        PUSD: {
          value: "",
          usdValue: "",
        },
      };
    }
    return {
      LongOpenInterest: {
        value: toDecimalPlaces(poolList[0]?.packedState?.longSize, 4),
        usdValue: toDecimalPlaces(
          multipliedBy(
            poolList[0]?.packedState?.longSize,
            poolList[0]?.indexPrice
          ),
          4
        ),
      },
      PLP: {
        value: toDecimalPlaces(lpt?.totalSupply, 4),
        usdValue: toDecimalPlaces(
          multipliedBy(lpt?.totalSupply, lpt?.LPTPrice),
          4
        ),
      },
      PUSD: {
        value: toDecimalPlaces(poolList[0]?.globalUsdPosition?.totalSupply, 4),
        usdValue: toDecimalPlaces(
          poolList[0]?.globalUsdPosition?.totalSupply,
          4
        ),
      },
    };
  }, [pools, lpt]);
  return (
    <div className="pt-3 sm:pt-[12px]">
      <StyleContainer className="space-y-[80px] sm:space-y-[40px]">
        <Step />
        <StepsChart data={summaryData} pool={pools[0]} />
        <Summary data={summaryData} />
      </StyleContainer>
    </div>
  );
}
