import { useMemo } from "react";
import { Typography, Card, Box, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { APP_LINK } from "constants/v3";

export default function Summary({ data }) {
  const SummaryArray = useMemo(() => {
    return [
      {
        title: "Total Supply",
        numberInUsd: `$${data?.PLP.usdValue}`,
        number: `${data?.PLP.value} PLP`,
        buttonText: "Buy PLP",
        link: `${APP_LINK}/pool`,
      },
      {
        title: "Open Interest",
        numberInUsd: `$${data?.LongOpenInterest.usdValue}`,
        number: `${data?.LongOpenInterest.value} ETH`,
        buttonText: "Open a position",
        link: `${APP_LINK}/longonly`,
      },
      {
        title: "PUSD Supply",
        numberInUsd: `${data?.PUSD.value} PUSD`,
        // numberInUsd: `$${data?.PUSD.value}`,
        number: "",
        buttonText: "Mint PUSD",
        link: `${APP_LINK}/pusd`,
      },
    ];
  }, [data]);

  return (
    <Card className="p-[40px] grid grid-cols-3 gap-2 sm:grid-cols-1 sm:space-y-[40px]">
      {SummaryArray.map((item, index) => {
        return (
          <Box key={index} className="space-y-1 text-center relative pb-3">
            <Box className="space-y-[10px]">
              <Typography variant="body1" align="center">
                {item.title}
              </Typography>
              <Typography variant="h5" fontWeight={600} align="center">
                {item.numberInUsd}
              </Typography>
              {item.number ? (
                <Typography
                  variant="body1"
                  align="center"
                  color="text.secondary"
                >
                  {item.number}
                </Typography>
              ) : (
                <Box className="h-[24px] sm:hidden"></Box>
              )}
            </Box>
            <NavLink to={item.link} className="absolute bottom-0 left-0 w-full">
              <Button
                variant="outlined"
                color="inherit"
                size="medium"
                sx={{
                  borderRadius: "20px",
                }}
              >
                {item.buttonText}
              </Button>
            </NavLink>
          </Box>
        );
      })}
    </Card>
  );
}
